var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pie-container"},[_c('div',{staticClass:"pie"},[_c('div',{class:[
        _vm.pieName == 'userPie1'
          ? 'main2'
          : _vm.pieName == 'userPie2'
          ? 'main2'
          : _vm.pieName == 'userPie5'
          ? 'main2'
          : 'main'
      ],attrs:{"id":_vm.pieName}}),(_vm.subTxt == '累计店铺总数')?_c('el-button',{class:[_vm.subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop'],attrs:{"type":"text"},on:{"click":function () {
          _vm.collect(
            'totalShopNum',
            '累计店铺数',
            [
              {
                name: '所属客户',
                type: 'input',
                modelKey: 'customerName'
              }
            ],
            [
              { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
              { name: '所属客户', colProp: ['customerName'] },
              { name: '关联订单数量', colProp: ['orderNum'] }
            ]
          );
        }}},[_vm._v(_vm._s(_vm.centerTxt))]):_vm._e(),(_vm.pieName == 'main2')?_c('el-button',{class:[_vm.subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop'],attrs:{"type":"text"},on:{"click":function () {
          _vm.collect(
            'serviceLoginShopNum',
            '服务中应上号店铺总数',
            [
              {
                name: '店铺名称',
                type: 'input',
                modelKey: 'shopName'
              },
              { name: '所属客户', type: 'input', modelKey: 'customerName' },
              {
                name: '所属平台',
                type: 'platSelect',
                modelKey: 'platformId',
                selOpt: this$1.platList
              }
            ],
            [
              { name: '店铺名称', colProp: ['shopName'] },
              { name: '所属客户', colProp: ['customerName'] },
              { name: '所属平台', colProp: ['platformName'] },
              { name: '关联订单数', colProp: ['orderNum'] }
            ]
          );
        }}},[_vm._v(_vm._s(_vm.centerTxt))]):_vm._e(),(_vm.pieName == 'main3')?_c('el-button',{class:[_vm.subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop'],attrs:{"type":"text"},on:{"click":function () {
          _vm.collect(
            'earlyWarningNum',
            '当前预警总数',
            [
              {
                name: '预警编号',
                type: 'input',
                modelKey: 'warningCode'
              },
              {
                name: '店铺名称',
                type: 'input',
                modelKey: 'shopName'
              },
              {
                name: '子账号名称',
                type: 'input',
                modelKey: 'subAccountName'
              },
              {
                name: '客服所属公司',
                type: 'input',
                modelKey: 'serviceCompanyId'
              },
              {
                name: '处理状态',
                type: 'select',
                modelKey: 'dealStatus',
                selOpt: [
                  { id: 'unDeal', name: '未处理' },
                  { id: 'deal', name: '已处理' }
                ]
              }
            ],
            [
              { name: '预警编号', colProp: ['warningCode'] },
              { name: '店铺名称', colProp: ['shopName'] },
              { name: '子账号名称', colProp: ['subAccountName'] },
              { name: '负责客服', colProp: ['adminNames'] },
              { name: '客服所属公司', colProp: ['companyName'] },
              { name: '处理状态 ', colProp: ['dealStatus'] }
            ]
          );
        }}},[_vm._v(_vm._s(_vm.centerTxt))]):_vm._e(),(_vm.pieName == 'main4')?_c('el-button',{class:[_vm.subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop'],attrs:{"type":"text"},on:{"click":function () {
          _vm.collect(
            'shouldQualityShopNum',
            '应质检店铺数',
            [
              { name: '店铺名称', type: 'input', modelKey: 'shopName' },

              {
                name: '所属客户',
                type: 'customerScreen',
                modelKey: 'customerId',
                selOpt: _vm.rolesCompany
              }
            ],
            [
              { name: '店铺名称', colProp: ['shopName'] },
              { name: '所属公司', colProp: ['cpmpanyName'] },
              { name: '所属客户', colProp: ['customerName'] },
              { name: '累计质检次数', colProp: ['qualityCount'] }
            ],
            _vm.selectDate
          );
        }}},[_vm._v(_vm._s(_vm.centerTxt))]):_vm._e(),(_vm.pieName == 'main5')?_c('el-button',{class:[_vm.subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop'],attrs:{"type":"text"},on:{"click":function () {
          _vm.collect(
            'totalPrice',
            '订单应收金额',
            [
              { name: '订单编号', type: 'input', modelKey: 'orderNo' },
              { name: '客户名称', type: 'input', modelKey: 'customerName' }
            ],
            [
              { name: '订单编号', colProp: ['orderNo'] },
              { name: '订单名称', colProp: ['orderName'] },
              { name: '客户名称', colProp: ['customerName'] },
              { name: '服务时间', colProp: ['startTime', 'endTime'] },
              { name: '应收金额', colProp: ['price'] },
              { name: '销售负责人', colProp: ['createName'] },
              { name: '备注', colProp: ['note'] }
            ]
          );
        }}},[_vm._v(" "+_vm._s(_vm.subTxt == '订单应收总金额' ? (_vm.centerNum.split('.')[0].length >5 ? '￥'+(Math.round(Number(_vm.centerNum) / 10000))+'w': (Number(_vm.centerNum) / 10000).toFixed(2) + 'w'):_vm.centerTxt)+" ")]):_vm._e(),(_vm.pieName == 'main6')?_c('el-button',{class:[_vm.subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop'],attrs:{"type":"text"},on:{"click":function () {
          _vm.collect(
            'clockNum',
            '累计打卡人数',
            [
              { name: '员工名称', type: 'input', modelKey: 'customerName' }
            ],
            [
              { name: '员工名称', colProp: ['name'] },
              { name: '所属公司', colProp: ['companyName'] },
              { name: '所属部门', colProp: ['departName'] },
              { name: '班次', colProp: ['frequencyName'] },
              { name: '上班打卡时间', colProp: ['time1'] },
              { name: '下班打卡时间', colProp: ['time2'] }
            ]
          );
        }}},[_vm._v(_vm._s(_vm.centerTxt))]):_vm._e()],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.centerTxt,"placement":"bottom"}},[(_vm.pieName == 'main5')?_c('el-button',{class:[_vm.subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop'],attrs:{"type":"text"},on:{"click":function () {
          _vm.collect(
            'totalPrice',
            '订单应收金额',
            [
              { name: '订单编号', type: 'input', modelKey: 'orderNo' },
              { name: '客户名称', type: 'input', modelKey: 'customerName' }
            ],
            [
              { name: '订单编号', colProp: ['orderNo'] },
              { name: '订单名称', colProp: ['orderName'] },
              { name: '客户名称', colProp: ['customerName'] },
              { name: '服务时间', colProp: ['startTime', 'endTime'] },
              { name: '应收金额', colProp: ['price'] },
              { name: '销售负责人', colProp: ['createName'] },
              { name: '备注', colProp: ['note'] }
            ]
          );
        }}},[_vm._v(" "+_vm._s(_vm.subTxt == '订单应收总金额' ? (_vm.centerNum.split('.')[0].length >5 ? '￥'+(Math.round(Number(_vm.centerNum) / 10000))+'w': (Number(_vm.centerNum) / 10000).toFixed(2) + 'w'):_vm.centerTxt)+" ")]):_vm._e()],1),_c('common-sum-dialog',{ref:"commonSumDialog",attrs:{"methodFuc":_vm.pieName == 'main'
        ? _vm.getMarketReportList
        : _vm.pieName == 'main2'
        ? _vm.customerUp
        : _vm.pieName == 'main3'
        ? _vm.customerUp
        : _vm.pieName == 'main4'
        ? _vm.getQualityReportList
        : _vm.pieName == 'main5'
        ? _vm.getFinanceReportDetail
        : _vm.pieName == 'main6'
        ? _vm.getReportDetail
        : '',"commonSumVisible":_vm.commonSumVisible},on:{"handleClose":_vm.sumVisibleClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }